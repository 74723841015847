import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './Navbar/Navbar';
import Footer from './Footer/Footer';
import HomePage from './Home/HomePage';
import Download from './Components/download/page';

// Products Pages
import IndustrialIotSuitePlatform from './Pages/Products/IndustrialIotSuitePlatform/page';
import DashboardApplication from './Pages/Products/DashboardApplication/page';
import ProcessGraphics from './Pages/Products/ProcessGraphics/page';
import IndustrialSystemConnectors from './Pages/Products/IndustrialSystemConnectors/page';
import OPCUA from './Pages/Products/OpcUAServerAndClient/page';
import ProtocolConverters from './Pages/Products/ProtocolConverters/page';
import NotificationReporting from './Pages/Products/NotificationAndReportingTools/page';

// Services Pages
import DigitalTransformation from './Pages/Services/DigitalTransformation/page';
import Private5g from './Pages/Services/Private5g/page';
import ProductInnovation from './Pages/Services/ProductInnovation/page';
import ResearchDevelopment from './Pages/Services/ResearchDevelopment/page';
import Consultancy from './Pages/Services/Consultancy/page';
import TechnologyTraining from './Pages/Services/TechnologyTraining/page'


function App() {
  return (
    <BrowserRouter>
      <div className="min-h-screen flex flex-col">
        <Navbar />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/download" element={<Download />} />

            {/* Products Page Routes  */}
            <Route path="/products/industrialiotsuiteplateform" element={<IndustrialIotSuitePlatform />} />
            <Route path="/products/dashboardingapplication" element={<DashboardApplication />} />
            <Route path="/products/processgraphics" element={<ProcessGraphics />} />
            <Route path="/products/industrialsystemconnectors" element={<IndustrialSystemConnectors />} />
            <Route path="/products/opcuaserverandclient" element={<OPCUA />} />
            <Route path="/products/protocolconverters" element={<ProtocolConverters />} />
            <Route path="/products/notificationandreportingtools" element={<NotificationReporting />} />

            {/* Services Page Routes  */}
            <Route path="/services/digitaltransformation" element={<DigitalTransformation />} />
            <Route path="/services/private5g" element={<Private5g />} />
            <Route path="/services/productinnovation" element={<ProductInnovation />} />
            <Route path="/services/researchdevelopment" element={<ResearchDevelopment />} />
            <Route path="/services/consultancy" element={<Consultancy />} />
            <Route path="/services/technologytraining" element={<TechnologyTraining />} />

          </Routes>
        </main>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;