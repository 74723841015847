import React, { useState } from 'react';
import data from "./download.json";
import Modal from './Model';

const Page = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleLinkClick = (item) => {
    setSelectedItem(item);
    setModalOpen(true);
  };

  return (
    <div className="container mx-auto p-4 max-w-7xl pt-28 pb-10 items-center justify-center flex flex-col">
      <h1 className="text-3xl font-bold mb-6">Product Library</h1>
      <div className="overflow-x-auto">
        <table className="table-auto w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-100 text-[14px]">
              <th className="px-4 py-2 text-left">Title</th>
              <th className="px-4 py-2 text-left">Date</th>
              <th className="px-4 py-2 text-left">Size</th>
              <th className="px-4 py-2 text-left">Publication Number</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr
                key={index}
                className="hover:bg-gray-100 transition duration-300 ease-in-out text-[14px]"
              >
                <td className="border px-4 py-2">
                  <button
                    onClick={() => handleLinkClick(item)}
                    className="hover:underline transition-colors duration-200"
                  >
                    {item.title}
                  </button>
                </td>
                <td className="border px-4 py-2">{item.date}</td>
                <td className="border px-4 py-2">{item.size}</td>
                <td className="border px-4 py-2">{item.publicationNumber}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal for requesting download */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        item={selectedItem}
      />
    </div>
  );
};

export default Page;
