// import React from "react";

// const Products = () => {
//   const cards = [
//     {
//       links: "/products/industrialiotsuiteplateform",
//       img: "/images/omniedge.png",
//       title: "Omni Edge AI Industrial IoT Platform Suite",
//       description:
//         "Our Industrial IoT Platform suite is cloud agnostics, onpremise IoT solution and Cyber Secured platform, that comes with Industrial Connectors",
//     },
//     {
//       links: "/products/industrialsystemconnectors",
//       img: "/images/Connectors.png",
//       title: "Connectors for Devices, Systems, and Applications",
//       description:
//         "We provide connectors to connect with various devices, systems, and application seamlessly to fetch the information. We have generic connectors such as for OPC UA, Modbus, OPC Da, CANBUS etc. and device specific connectors. Our Connector can be deployment and configured on the Edge Gateway.",
//     },
//     {
//       links: "/products/opcuaserverandclient",
//       img: "/images/OPC.png",
//       title: "OPC UA Server",
//       description:
//         "We are expert in developing OPC UA Server, and various OPC UA based applications, like OPC UA server for testing, OPC UA PubSub, OPC UA Test Server, OPC UA Clinet, thin client for embedded application, OPC UA Server connector of Edge Gateway",
//     },
//     {
//       links: "/products/dashboardingapplication",
//       img: "/images/dashboardmanager.png",
//       title: "Dashboard Manager",
//       description:
//         "Its a No-Code platform to design visualizations for monitoring real time industrial process data",
//     },
//     {
//       links: "/products/notificationandreportingtools",
//       img: "/images/Notification&Reporting.png",
//       title: "Notification and Reporting",
//       description: "We provide solution for notification and report generation",
//     },
//     {
//       links: "/products/protocolconverters",
//       img: "/images/protocolconverter.png",
//       title: "Protocol Converters",
//       description:
//         "We provide protocol converts module, that helps to connect legacy system with other digital system.",
//     },
//     {
//       links: "/products/processgraphics",
//       img: "/images/processgraphics.png",
//       title: "Process Graphics",
//       description:
//         "This application provides no code platform to Industrial Process workflow design, Realtime visualization for operator",
//     },
//     {
//       links: "/products/engineeringtool",
//       img: "/images/enggtool.png",
//       title: "Engineering Tool",
//       description:
//         "Its No code platform to create device specific data model, industrial connector and edge application. By simply drag and drop, edge application can be designed and developed.",
//     },
//   ];

//   return (
//     <div className="mb-4 mt-10 flex flex-col items-center justify-center max-w-7xl mx-auto">
//       <div className="flex justify-start w-full">
//         <h1 className="text-5xl text-sky-600  font-bold ">
//           Our Offerings
//         </h1>
//       </div>

//       <div className="w-full mt-10">
//         <h1 className="border-b-2 border-gray-500 text-3xl font-bold text-sky-600">
//           Products
//         </h1>
//       </div>

//       <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 mt-5 max-w-7xl mx-auto">
//         {cards.map((card, index) => (
//           <a
//             key={index} href={card.links}
//             className="bg-white border hover:border-transparent hover:scale-105 transition-transform duration-500 shadow-lg backdrop-blur-md rounded-lg cursor-pointer text-black flex flex-col"
//           >
//             <img
//               className="w-full h-auto md:h-48 rounded-t-lg object-cover"
//               src={card.img}
//               width={600} height={400}
//               alt={card.title}
//             />
//             <div className="p-4 flex-grow">
//               <h1 className="text-lg font-semibold mb-2">{card.title}</h1>
//               <p className="text-sm text-gray-600 mb-4">{card.description}</p>
//               <a
//                 href={card.links}
//                 className="text-sm text-sky-500 hover:text-sky-700 underline"
//               >
//                 Read More
//               </a>
//             </div>
//           </a>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Products;







import React from "react";
import { ArrowRight } from 'lucide-react';

const Products = () => {
  const cards = [
    {
      links: "/products/industrialiotsuiteplateform",
      img: "/images/omniedge.png",
      title: "Omni Edge AI Industrial IoT Platform Suite",
      description:
        "Our Industrial IoT Platform suite is cloud agnostics, onpremise IoT solution and Cyber Secured platform, that comes with Industrial Connectors",
    },
    {
      links: "/products/industrialsystemconnectors",
      img: "/images/Connectors.png",
      title: "Connectors for Devices, Systems, and Applications",
      description:
        "We provide connectors to connect with various devices, systems, and application seamlessly to fetch the information. We have generic connectors such as for OPC UA, Modbus, OPC Da, CANBUS etc. and device specific connectors. Our Connector can be deployment and configured on the Edge Gateway.",
    },
    {
      links: "/products/opcuaserverandclient",
      img: "/images/OPC.png",
      title: "OPC UA Server",
      description:
        "We are expert in developing OPC UA Server, and various OPC UA based applications, like OPC UA server for testing, OPC UA PubSub, OPC UA Test Server, OPC UA Clinet, thin client for embedded application, OPC UA Server connector of Edge Gateway",
    },
    {
      links: "/products/dashboardingapplication",
      img: "/images/dashboardmanager.png",
      title: "Dashboard Builder",
      description:
        "Its a No-Code platform to design visualizations for monitoring real time industrial process data",
    },
    {
      links: "/products/notificationandreportingtools",
      img: "/images/Notification&Reporting.png",
      title: "Notification and Reporting",
      description: "We provide solution for notification and report generation",
    },
    {
      links: "/products/protocolconverters",
      img: "/images/protocolconverter.png",
      title: "Protocol Converters",
      description:
        "We provide protocol converts module, that helps to connect legacy system with other digital system.",
    },
    {
      links: "/products/processgraphics",
      img: "/images/processgraphics.png",
      title: "Process Graphics Builder",
      description:
        "This application provides no code platform to Industrial Process workflow design, Realtime visualization for operator",
    },
    {
      links: "/products/engineeringtool",
      img: "/images/enggtool.png",
      title: "Engineering Tool",
      description:
        "Its No code platform to create device specific data model, industrial connector and edge application. By simply drag and drop, edge application can be designed and developed.",
    },
  ];

  return (
    <div className="bg-gray-50 min-h-screen py-12 px-2 sm:px-4 lg:px-6">
      <div className="max-w-7xl mx-auto">
        <div className="mb-16">
          <h1 className="text-4xl font-bold text-gray-900 sm:text-5xl">
            Our <span className="text-sky-600">Offerings</span>
          </h1>
          <div className="mt-8">
            <h2 className="text-2xl font-semibold text-gray-900 inline-block border-b-4 border-sky-600 pb-2">
              Products
            </h2>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {cards.map((card, index) => (
            // <a
            //   key={index}
            //   href={card.links}
            //   className="group relative bg-white rounded-2xl overflow-hidden shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
            // >
            <h1
              key={index}
              className="group relative bg-white rounded-2xl overflow-hidden shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
            >
              <div className="aspect-w-16 aspect-h-9 bg-gray-200">
                <img
                  src={card.img}
                  alt={card.title}
                  className="w-full h-48 object-cover"
                />
              </div>

              <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              <div className="p-6">
                <div className="h-16">
                  <h3 className="text-xl font-bold text-gray-900 line-clamp-2 group-hover:text-sky-600 transition-colors duration-300">
                    {card.title}
                  </h3>
                </div>
                <p className="mt-3 text-md text-gray-600 line-clamp-3">
                  {card.description}
                </p>
                <div className="mt-4 flex items-center">
                  <span className="text-sky-600 text-sm font-medium group-hover:text-sky-700 transition-colors duration-300">
                    {/* Read more */}
                    <ArrowRight className="w-4 h-3 ml-2 inline-block transition-transform duration-300 group-hover:translate-x-2" />
                    {/* <span className="ml-2 inline-block transition-transform duration-300 group-hover:translate-x-1">
                      →
                    </span> */}
                  </span>
                </div>
              </div>
            </h1>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Products;