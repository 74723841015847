import React from "react";

const Private5g = () => {
  const service = {
    name: "Private 5G based Solution Development for OT",
    description:
      "Private 5G provides high reliability, low latency, and high throughput wireless communication infrastructure, which is very suitable for OT networks in manufacturing, Industrial Automation, Warehouse management likewise applications. We have expertise in developing end-to-end OT and IT solutions on private 5G network, interfacing non-5G devices and system with 5G networks. ",
    image: "/images/private-industrial-iot-network.jpg",
    feature: [
      {
        id: 1,
        name: "Feature 1",
        comment: [
          "Cloud agnostics",
          "Remote management AI/ML enabled",
          "Rule engine Visualization and reportings",
        ],
        image: "/images/private-industrial-iot-network.jpg",
      },
      {
        id: 2,
        name: "Feature 2",
        comment: [
          "Cloud agnostics",
          "Remote management AI/ML enabled",
          "Rule engine Visualization and reportings",
        ],
        image: "/images/private-industrial-iot-network.jpg"
      },
    ],
  };

  if (!service) {
    return <h2>Service not found</h2>;
  }

  return (
    <div className="mx-auto md:w-[88%]">
      <div className="flex justify-center">
        <div className="w-full overflow-hidden">
          <img
            className="w-full h-[300px] md:w-full md:h-[400px]"
            src={service.image}
            alt={service.name}
          />
          <div className="px-6 py-8">
            <div className="font-bold text-3xl mb-2 flex justify-center items-center">
              {service.name}
            </div>
            <p className="text-gray-700 text-base">{service.description}</p>
          </div>
          {/* <div className="px-6 ">
                        <h3 className="font-bold text-2xl m-6 flex justify-center items-center">Features</h3>
                        {service.feature.map(feature => (
                            <div key={feature.id} className="flex justify-between p-6">
                                <div className="pr-4">
                                    <div className="font-semibold text-lg mb-2 pl-4">{feature.name}</div>
                                    <ul className="text-gray-700 text-base list-disc ml-6">
                                        {Array.isArray(feature.comment) ? (
                                            feature.comment.map((item, index) => (
                                                <li key={index}>{item}</li>
                                            ))
                                        ) : (
                                            <li>{feature.comment}</li>
                                        )}
                                    </ul>
                                </div>
                                <div className="w-[50vh] h-[50vh] m-4 overflow-hidden">
                                    <img className="md:w-[50vh] md:h-[50vh]" src={feature.image} alt={`Feature ${feature.id}`} />
                                </div>
                            </div>
                        ))}
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default Private5g;
