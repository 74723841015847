// Components/HomePage/index.js
import React from 'react';
import Banner from './Banner/Banner';
import Products from './OurOfferings/Products';
import Services from './OurOfferings/Service';

const HomePage = () => {
    return (
        <>
            <Banner />
            <Products />
            <Services />
        </>
    );
};

export default HomePage;