import React from 'react'
import Data from './Navigation.json';

const Services = () => {
  return (
    <div>
      <ul className='w-full p-2 '>
        {Data.Services.map((Services, index) => (
          <li key={index} className='flex p-1 w-full'>
            {/* <a
              href={Services.link}
              className='hover:bg-sky-200 p-2 rounded w-full'>
              {Services.label}
            </a> */}
            <h1
              className='hover:bg-sky-200 p-2 rounded w-full'>
              {Services.label}
            </h1>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Services