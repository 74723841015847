import React from "react";
import Data from "./industrialiotsuiteplateform.json";

const Page = () => {
  return (
    <div>
      {/* Background Image with Text Overlay */}
      <div className="relative w-full h-[50vh] sm:h-[70vh] md:h-[90vh]">
        <img
          src="/images/platformstack.png"
          alt="Background"
          className="absolute inset-0 w-full h-full object-cover"
        />
      </div>
      <div className="max-w-7xl mx-auto w-full px-4 md:px-8 lg:px-16 lg:mt-10 mt-4">
        <h1 className="text-3xl text-sky-600 font-bold underline">
          Industrial IoT Platform Features
        </h1>
      </div>
      {/* Sections with Dynamic Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 md:gap-10 px-4 md:px-8 lg:px-16 lg:mt-20 mt-10 max-w-7xl mx-auto mb-10">
        {Data.map((item, index) => (
          <div
            key={index}
            className="bg-white rounded-xl shadow-lg p-6 w-full flex items-center justify-center gap-4 border"
          >
            <div>
              <h3 className="text-lg md:text-xl font-semibold">
                {item.title}
              </h3>
              <p className="text-gray-600 text-sm md:text-base">
                {item.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Page;