import React, { useState } from 'react';
import Data from './Navigation.json';

const Products = () => {
  const [hoveredProduct, setHoveredProduct] = useState(null);

  return (
    <div className="flex">
      {/* Left section: List of products */}
      <ul className=" p-2">
        {Data.Products.map((product, index) => (
          <li
            key={index}
            className="flex p-1 w-full"
            onMouseEnter={() => setHoveredProduct(product)}
            onMouseLeave={() => setHoveredProduct(null)}
          >
            {/* <a
              href={product.link}
              className="hover:bg-sky-200 p-2 rounded w-full">
              {product.label}
            </a> */}
            <h1
              className="hover:bg-sky-200 p-2 rounded w-full">
              {product.label}
            </h1>
          </li>
        ))}
      </ul>

      {/* Right section: Show image of hovered product */}
      {/* <div className="w-1/2 flex justify-center items-center p-2">
        {hoveredProduct ? (
          <img
            src={hoveredProduct.image} 
            alt={hoveredProduct.label}
            layout="fill"
            className="object-contain h-64 w-64" 
          />
        ) : (
          <p className="text-gray-500">Hover over a product to see the image</p>
        )}
      </div> */}
    </div>
  );
};

export default Products;
