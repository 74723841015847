"use client";
import React, { useState, useEffect } from "react";
import { ArrowRight } from 'lucide-react';
import "./Banner.css";
import bannerFeatures from "./BannerFeatures.json"; // Ensure the JSON file is correctly imported

const Banner = () => {
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    setFeatures(bannerFeatures);
  }, []);

  return (
    <div className="text-center py-12 bg-gray-50 relative bg-cover flex flex-col items-center pt-40">
      {/* Main Banner */}
      <div className="">
        <div className="px-4 md:px-8 lg:px-16">
          <h1 className="text-2xl md:text-3xl lg:text-6xl font-bold text-white">
            We Provide Best-in-Class Industrial <br />
            <span className="text-sky-400">IoT Platform Suite</span>
          </h1>
          <p className="text-base md:text-lg lg:text-xl text-white mt-4 mb-8">
            Empowering your digital infrastructure with cyber secure, reliable,
            scalable, on-premise solution. That enables real-time data
            acquisition from industrial systems and equipment, contextualizing
            and normalizing data in various formats, processing the data using
            AI/ML, and generating insightful information.
          </p>
          {/* <a
            href={"https://forms.office.com/r/MPSuh3QYKC"}
            className="bg-sky-500 text-white py-2 md:py-3 px-4 md:px-6 rounded-lg hover:bg-sky-600 hover:scale-105 transition duration-300 " target="_blank" rel="noopener noreferrer"
          >
            Request a Demo
          </a> */}
          <a
            href={"https://forms.office.com/r/MPSuh3QYKC"} target="_blank" rel="noopener noreferrer"
            className="inline-flex items-center justify-center w-fit px-6 py-3 text-sm font-medium text-white bg-sky-600 rounded-lg hover:bg-sky-700 transition-colors duration-300 group">
            Request a Demo
            <ArrowRight className="w-4 h-4 ml-2 transition-transform duration-300 group-hover:translate-x-2" />
          </a>
        </div>

        {/* Features Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 md:gap-10 px-4 md:px-8 lg:px-16 lg:mt-40 mt-10">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-white rounded-xl shadow-lg p-6 w-full flex items-center justify-center gap-4 hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
            >
              <div>
                <h3 className="text-lg md:text-xl font-semibold flex items-center justify-center gap-6 p-2">
                  <img
                    src={feature.image}
                    alt={feature.title}
                    className="w-10 h-8 object-contain"
                  />
                  {feature.title}
                </h3>
                <p className="text-gray-600 text-sm md:text-base text-center">
                  {feature.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Banner;
