import React from 'react';

const Footer = () => {
    return (
        <div className="bg-slate-900 text-gray-300 py-4 px-2">
            <div className="max-w-7xl mx-auto border-b-2 border-gray-600 py-8">
                <div className="px-12 flex flex-wrap justify-between">
                    <div className="w-full sm:w-auto">
                        <h6 className="font-bold uppercase pt-2">Address</h6>
                        <ul>
                            <li className="py-1 text-gray-500 hover:text-white">
                                Digital Sync Solutions and Services
                            </li>
                            <li className="py-1 text-gray-500 hover:text-white">
                                ITPL Road, Whitefield, Bengaluru- 560037
                            </li>
                            <li className="py-1 text-gray-500 hover:text-white">
                                Email-info@digital-sync.com
                            </li>
                            <li className="py-1 text-gray-500 hover:text-white">
                                Phone:+91 72044-35361, 080-47565585
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="flex flex-col px-2 py-2 mx-auto justify-center sm:flex-row text-center text-gray-500">
                <p className="py-2">2024 Digital Sync, All rights reserved</p>
            </div>
        </div>
    );
};

export default Footer;