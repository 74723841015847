import React, { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { IoIosMail } from "react-icons/io";
import { MdCall } from "react-icons/md";
import { GrContactInfo } from "react-icons/gr";
import Products from "./Navigation/Products";
import Services from "./Navigation/Services";
import Contactus from "./Navigation/Contactus";

const Navbar = () => {
    const [dropdownStates, setDropdownStates] = useState({
        product: false,
        services: false,
        contactus: false,
        contactDetails: false,
        hamburger: false,
    });

    const toggleDropdown = (menu) => {
        setDropdownStates((prevState) => ({
            ...prevState,
            product: false,
            services: false,
            contactus: false,
            contactDetails: false,
            [menu]: !prevState[menu],
        }));
    };

    const handleHover = (menu, isOpen) => {
        setDropdownStates((prevState) => ({
            ...prevState,
            [menu]: isOpen,
        }));
    };

    // const handleDownload = () => {
    //     const pdfUrl = "/Digital Sync Omni Edge AI Industrial IoT Platform Suite.pdf";
    //     const link = document.createElement("a");
    //     link.href = pdfUrl;
    //     link.download = "PlatformFeatures.pdf";
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // };

    return (
        <nav className="bg-white border-b-2 shadow-md fixed z-10 w-full">
            <div className="sm:px-6">
                <div className="flex justify-between items-center py-4 w-full">
                    {/* Logo */}
                    <div>
                        <a href="/" className="">
                            <img
                                src="/images/Picture1.png"
                                alt="Logo"
                                width={200}
                                height={50}
                                className="pl-4"
                            />
                        </a>
                    </div>

                    {/* Desktop Menu */}
                    <div className="hidden md:flex items-center space-x-6 lg:pr-14 text-[14px] lg:text-[17px]">
                        {/* Product Dropdown */}
                        <div
                            className="relative group"
                            onMouseEnter={() => handleHover("product", true)}
                            onMouseLeave={() => handleHover("product", false)}
                        >
                            <button
                                onClick={() => toggleDropdown("product")}
                                className="flex items-center text-gray-900 hover:text-sky-500 focus:outline-none"
                            >
                                Product
                                <FiChevronDown
                                    className={`ml-1 transition-transform ${dropdownStates.product ? "rotate-180" : "rotate-0"
                                        }`}
                                />
                            </button>
                            {dropdownStates.product && (
                                <div className="absolute bg-white shadow-md rounded-md w-[600px] z-10">
                                    <Products />
                                </div>
                            )}
                        </div>

                        {/* Services Dropdown */}
                        <div
                            className="relative group"
                            onMouseEnter={() => handleHover("services", true)}
                            onMouseLeave={() => handleHover("services", false)}
                        >
                            <button
                                onClick={() => toggleDropdown("services")}
                                className="flex items-center text-gray-900 hover:text-sky-500 focus:outline-none"
                            >
                                Services
                                <FiChevronDown
                                    className={`ml-1 transition-transform ${dropdownStates.services ? "rotate-180" : "rotate-0"
                                        }`}
                                />
                            </button>
                            {dropdownStates.services && (
                                <div className="absolute bg-white shadow-md rounded-md w-64 z-10">
                                    <Services />
                                </div>
                            )}
                        </div>

                        {/* Static Links */}
                        <a href="/" className="text-gray-900 hover:text-sky-500">
                            Industries
                        </a>
                        <a href="/" className="text-gray-900 hover:text-sky-500">
                            Pricing
                        </a>
                        <a href="/" className="text-gray-900 hover:text-sky-500">
                            About Us
                        </a>
                        <a href="#" className="text-gray-900 hover:text-sky-500">
                            Downloads
                        </a>

                        {/* Contact Us Dropdown */}
                        <div
                            className="relative group"
                            onMouseEnter={() => handleHover("contactus", true)}
                            onMouseLeave={() => handleHover("contactus", false)}
                        >
                            <button
                                onClick={() => toggleDropdown("contactus")}
                                className="flex items-center text-gray-900 hover:text-sky-500 focus:outline-none"
                            >
                                Contact Us
                                <FiChevronDown
                                    className={`ml-1 transition-transform ${dropdownStates.contactus ? "rotate-180" : "rotate-0"
                                        }`}
                                />
                            </button>
                            {dropdownStates.contactus && (
                                <div className="absolute bg-white shadow-md rounded-md w-48 z-10">
                                    <Contactus />
                                </div>
                            )}
                        </div>

                        {/* Contact Details for Large Screens */}
                        <div className="hidden lg:flex items-center space-x-4">
                            <div className="text-gray-900 hover:text-sky-500">
                                <IoIosMail className="inline-block mr-2" />
                                <a href="mailto:info@digital-sync.com">info@digital-sync.com</a>
                            </div>
                            <div className="text-gray-900 hover:text-sky-500">
                                <MdCall className="inline-block mr-2" />
                                <a href="tel:+917204435361">+91 72044 35361</a>
                            </div>
                        </div>

                        {/* Contact Details as Icons for Medium Screens */}
                        <div
                            className="hidden md:block lg:hidden"
                            onMouseEnter={() => handleHover("contactDetails", true)}
                            onMouseLeave={() => handleHover("contactDetails", false)}
                        >
                            <GrContactInfo className="text-2xl text-gray-900 hover:text-sky-500 cursor-pointer" />
                            {dropdownStates.contactDetails && (
                                <div className="absolute right-0 bg-white shadow-md rounded-md z-10 w-48 p-2">
                                    <a
                                        href="mailto:info@digital-sync.com"
                                        className="block px-4 py-2 text-gray-900 hover:bg-blue-100"
                                    >
                                        <IoIosMail className="inline-block mr-2" />
                                        info@digital-sync.com
                                    </a>
                                    <a
                                        href="tel:+917204435361"
                                        className="block px-4 py-2 text-gray-900 hover:bg-blue-100"
                                    >
                                        <MdCall className="inline-block mr-2" />
                                        +91 72044 35361
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Mobile Menu Toggle */}
                    <div className="md:hidden flex items-center z-10 pr-4">
                        <button
                            onClick={() => toggleDropdown("hamburger")}
                            className="text-gray-800 hover:text-sky-500 focus:outline-none"
                        >
                            <svg
                                className="h-6 w-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d={
                                        dropdownStates.hamburger
                                            ? "M6 18L18 6M6 6l12 12"
                                            : "M4 6h16M4 12h16M4 18h16"
                                    }
                                ></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            {/* Mobile Menu */}
            <div
                className={`md:hidden fixed top-0 left-0 w-full shadow-md bg-white transform ${dropdownStates.hamburger ? "translate-x-0" : "-translate-x-full"
                    } transition-transform duration-300 ease-in-out`}
            >
                <div className="py-6 px-6 flex flex-col gap-4">
                    {/* Product Dropdown */}
                    <div className="relative">
                        <button
                            onClick={() => toggleDropdown("product")}
                            className="flex items-center text-gray-900 hover:text-sky-500 focus:outline-none"
                        >
                            Product
                            <FiChevronDown
                                className={`ml-1 transition-transform ${dropdownStates.product ? "rotate-180" : "rotate-0"
                                    }`}
                            />
                        </button>
                        {dropdownStates.product && (
                            <div className="w-full z-10 mt-2">
                                <Products />
                            </div>
                        )}
                    </div>

                    {/* Services Dropdown */}
                    <div className="relative">
                        <button
                            onClick={() => toggleDropdown("services")}
                            className="flex items-center text-gray-900 hover:text-sky-500 focus:outline-none"
                        >
                            Services
                            <FiChevronDown
                                className={`ml-1 transition-transform ${dropdownStates.services ? "rotate-180" : "rotate-0"
                                    }`}
                            />
                        </button>
                        {dropdownStates.services && (
                            <div className="w-full z-10 mt-2">
                                <Services />
                            </div>
                        )}
                    </div>

                    {/* Static Links */}
                    <a href="/industries" className="text-gray-900 hover:text-sky-500">
                        Industries
                    </a>
                    <a href="/pricing" className="text-gray-900 hover:text-sky-500">
                        Pricing
                    </a>
                    <a href="/about" className="text-gray-900 hover:text-sky-500">
                        About Us
                    </a>
                    <a href="/download" className="text-gray-900 hover:text-sky-500">
                        Downloads
                    </a>

                    {/* Contact Us Dropdown */}
                    <div className="relative">
                        <button
                            onClick={() => toggleDropdown("contactus")}
                            className="flex items-center text-gray-900 hover:text-sky-500 focus:outline-none"
                        >
                            Contact Us
                            <FiChevronDown
                                className={`ml-1 transition-transform ${dropdownStates.contactus ? "rotate-180" : "rotate-0"
                                    }`}
                            />
                        </button>
                        {dropdownStates.contactus && (
                            <div className="w-full z-10 mt-2">
                                <Contactus />
                            </div>
                        )}
                    </div>

                    {/* Contact Info */}
                    <div className="">
                        <a
                            href="mailto:info@digital-sync.com"
                            className="block py-2 text-gray-900 hover:bg-blue-100"
                        >
                            <IoIosMail className="inline-block mr-2" />
                            info@digital-sync.com
                        </a>
                        <a
                            href="tel:+917204435361"
                            className="block py-2 text-gray-900 hover:bg-blue-100"
                        >
                            <MdCall className="inline-block mr-2" />
                            +91 72044 35361
                        </a>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;