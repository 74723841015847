// import React from 'react';

// // Reusable Card component
// const Card = ({ icon, title, description, links }) => (
//     <a href={links}>
//         <div className="bg-white rounded-lg shadow-md py-4 px-6 hover:bg-sky-400 transition-transform duration-300 hover:scale-105 hover:shadow-lg transform hover:-translate-y-1 flex flex-col h-full">
//             <div className="flex items-center mb-4">
//                 <span className="text-4xl text-sky-600 mr-4">{icon}</span>
//                 <h3 className="text-md font-semibold text-gray-800">{title}</h3>
//             </div>
//             <p className="text-gray-600 mb-4 flex-grow">{description}</p>
//             <div className="text-sm text-sky-800 hover:text-sky-600 hover:underline mt-auto">
//                 Read More
//             </div>
//         </div>
//     </a>
// );

// const App = () => (
//     <div className="flex justify-center py-12 bg-gray-100">
//         <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//             {/* Left Section with Background Image and Text */}
//             <div className="relative flex flex-col justify-center items-start h-[400px] lg:h-auto">
//                 <div className="relative w-full h-full">
//                     <img
//                         src="/images/iotbackgroundwhite.jpeg"
//                         alt="IoT Background"
//                         className="absolute w-full h-full object-cover brightness-50 rounded-lg"
//                     />
//                     <div className="absolute inset-0 flex justify-center items-center flex-col text-center p-6">
//                         <h1 className="text-white text-3xl md:text-4xl lg:text-5xl font-bold mb-4">Services</h1>
//                         <p className="text-white text-sm md:text-base lg:text-lg leading-relaxed">
//                             We offer a wide range of services, including private 5G solutions, digital transformation, consultancy, and product innovation, to help your business thrive in the modern tech landscape.
//                         </p>
//                         <button className="bg-sky-600 w-40 mt-8 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full hover:scale-x-105 transition-transform duration-300">
//                             Learn More
//                         </button>
//                     </div>
//                 </div>
//             </div>

//             {/* Right Section with Service Cards */}
//             <div>
//                 <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
//                     <Card
//                         icon="📶"
//                         links="/services/private5g"
//                         title="Private 5G Based Solution Development"
//                         description="Private 5G provides high reliability, low latency, and high throughput communication, making it ideal for OT networks in manufacturing, industrial automation, and more."
//                     />
//                     <Card
//                         icon="🔄"
//                         links="/services/digitaltransformation"
//                         title="Digital Transformation Solution Development"
//                         description="We help your business navigate the digital transformation journey by offering tailored solutions for every phase, ensuring measurable value at each step."
//                     />
//                     <Card
//                         icon="👥"
//                         links="/services/consultancy"
//                         title="Consultancy"
//                         description="Our consultancy services include OT application development, cyber security analysis, solution architecting, and more, helping you optimize industrial IoT solutions."
//                     />
//                     <Card
//                         icon="📚"
//                         links="/services/technologytraining"
//                         title="Technology Trainings"
//                         description="We provide corporate training on technologies like Docker, Kubernetes, OPC UA, IoT, industrial communication protocols, and cyber security."
//                     />
//                     <Card
//                         icon="🔬"
//                         links="/services/researchdevelopment"
//                         title="Research and Development"
//                         description="We support your R&D efforts by providing competitor analysis, proof of concept development, and innovation workshops to help you stay ahead of the curve."
//                     />
//                     <Card
//                         icon="💡"
//                         links="/services/productinnovation"
//                         title="Product Innovation Services"
//                         description="We help you develop innovative products and services, protect your intellectual property, and file patents, allowing your business to seize new market opportunities."
//                     />
//                 </div>
//             </div>
//         </div>
//     </div>
// );

// export default App;




import React from 'react';
import { ArrowRight, Radio, RefreshCw, Users, BookOpen, FlaskConical, Lightbulb } from 'lucide-react';

const Card = ({ Icon, title, description, links }) => (
    // <a href={links} className="group">
    <h1 className="group">
        <div className="relative bg-white rounded-xl overflow-hidden p-6 h-full shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
            <div className="absolute top-0 right-0 w-32 h-32 -mr-16 -mt-16 bg-sky-500/20 rounded-full transition-transform group-hover:scale-150 duration-500" />

            <div className="relative">
                <div className="w-12 h-12 flex items-center justify-center rounded-lg bg-gradient-to-br from-sky-500 to-sky-600 text-white mb-6">
                    <Icon className="w-6 h-6" />
                </div>

                <h3 className="text-xl font-bold text-gray-900 mb-3 group-hover:text-sky-600 transition-colors duration-300">
                    {title}
                </h3>

                <p className="text-gray-600 text-md leading-relaxed mb-6">
                    {description}
                </p>

                <div className="flex items-center text-sky-600 font-medium text-sm">
                    Read More
                    <ArrowRight className="w-4 h-4 ml-2 transition-transform duration-300 group-hover:translate-x-2" />
                </div>
            </div>
        </div>
    </h1>
);

const App = () => {
    const services = [
        {
            Icon: Radio,
            title: "Private 5G Based Solution Development",
            description: "Private 5G provides high reliability, low latency, and high throughput communication, making it ideal for OT networks in manufacturing, industrial automation, and more.",
            links: "/services/private5g"
        },
        {
            Icon: RefreshCw,
            title: "Digital Transformation Solution Development",
            description: "We help your business navigate the digital transformation journey by offering tailored solutions for every phase, ensuring measurable value at each step.",
            links: "/services/digitaltransformation"
        },
        {
            Icon: Users,
            title: "Consultancy",
            description: "Our consultancy services include OT application development, cyber security analysis, solution architecting, and more, helping you optimize industrial IoT solutions.",
            links: "/services/consultancy"
        },
        {
            Icon: BookOpen,
            title: "Technology Trainings",
            description: "We provide corporate training on technologies like Docker, Kubernetes, OPC UA, IoT, industrial communication protocols, and cyber security.",
            links: "/services/technologytraining"
        },
        {
            Icon: FlaskConical,
            title: "Research and Development",
            description: "We support your R&D efforts by providing competitor analysis, proof of concept development, and innovation workshops to help you stay ahead of the curve.",
            links: "/services/researchdevelopment"
        },
        {
            Icon: Lightbulb,
            title: "Product Innovation Services",
            description: "We help you develop innovative products and services, protect your intellectual property, and file patents, allowing your business to seize new market opportunities.",
            links: "/services/productinnovation"
        }
    ];

    return (
        <div className="min-h-screen bg-gray-50 py-16">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
                    {/* Hero Section */}
                    <div className="relative rounded-2xl overflow-hidden shadow-xl">
                        <img
                            src="/images/iotbackgroundwhite.jpeg"
                            alt="IoT Background"
                            className="absolute w-full h-full object-cover"
                        />
                        <div className="absolute inset-0 bg-gradient-to-r from-sky-200/50 to-sky-700/80" />
                        <div className="relative h-full flex flex-col justify-center p-8 sm:p-12">
                            <h1 className="text-4xl sm:text-5xl font-bold text-white mb-6">
                                Our Services
                            </h1>
                            <p className="text-white text-lg leading-relaxed mb-8 max-w-lg">
                                We offer a wide range of services, including private 5G solutions, digital transformation, consultancy, and product innovation, to help your business thrive in the modern tech landscape.
                            </p>
                            <button className="inline-flex items-center justify-center w-fit px-6 py-3 text-sm font-medium text-white bg-sky-600 rounded-lg hover:bg-sky-700 transition-colors duration-300 group">
                                Explore Services
                                <ArrowRight className="w-4 h-4 ml-2 transition-transform duration-300 group-hover:translate-x-2" />
                            </button>
                        </div>
                    </div>

                    {/* Services Grid */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                        {services.map((service, index) => (
                            <Card key={index} {...service} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default App;