import React from 'react';
import Data from './Navigation.json';

const Contactus = () => {
  return (
    <div>
      <ul className='w-full p-2'>
        {Data.Contactus.map((contactItem, index) => (
          <li key={index} className='flex p-1 w-full'>
            <a
              href={contactItem.link}
              target="_blank"
              rel="noopener noreferrer"
              className='hover:bg-sky-200 p-2 rounded w-full'
            >
              {contactItem.label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Contactus;